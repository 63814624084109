import React from "react";

import Logo from "../../assets/img/LogoHome_Site_Natal2024.png";

import "./styles.css";

function Loading() {
  return (
    <div className="loading-wrapper">
      <img src={Logo} alt="Natal com #TimeCEJAM" />
    </div>
  );
}

export default Loading;

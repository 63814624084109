import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import ImageViewer from "./ImageViewer";

function Votar() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedPicture, setSelectedPicture] = useState(null);
  const [cards, setCards] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [cookie, setCookie] = useState(null);
  const [message, setMessage] = useState("Confirmar voto?");
  const [timeoutPopup, setTimeoutPopup] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const matches =
      document.cookie.match(
        new RegExp(
          "(?:^|; )" +
            "vote_valid".replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
            "=([^;]*)"
        )
      ) || [];

    if (matches.length) {
      setTimeoutPopup(true);
    }

    setCookie(matches.length);
  }, []);

  useEffect(() => {
    fetchData("https://cejam.org.br/api/concurso", {});
  }, []);

  useEffect(() => {
    if (selectedCard && !cookie) {
      setConfirm(true);
    }
  }, [selectedCard]);

  if (isLoading) {
    return <Loading />;
  }

  async function fetchData(url, options) {
    try {
      const res = await fetch(url, options);
      const results = await res.json();
      setCards(results);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  function handleVoteSelection(e) {
    if (!cookie) setSelectedCard(e.currentTarget.id);
  }

  async function handleVote(e) {
    e.currentTarget.setAttribute("disabled", true);
    const vote = await fetch("https://cejam.org.br/api/concurso/votar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        id: selectedCard,
      }),
    });

    if (vote.status === 200) {
      setSelectedCard(null);
      document.cookie = `vote_valid=valid ; max-age=300`;
      setMessage("Voto confirmado!");
      setCookie(["voted"]);

      setTimeout(() => {
        setConfirm(false);
        setMessage("Confirmar voto?");
        e.currentTarget.removeAttribute("disabled");
      }, 2000);
    }
  }

  function handleCancelVote() {
    setConfirm(false);
    setSelectedCard(null);
  }

  return (
    <section className="content votar">
      <div className="heading">
        <h1 className="title">Deixe seu voto</h1>
      </div>

      <p className="text">
        Escolha uma opção e deixe sua votação abaixo. Os desenhos mais votados
        serão estampados em cartões de natal institucionais, que ficarão
        disponíveis para download e compartilhamento.
      </p>

      <h5>Clique nas imagens para ampliar</h5>

      <ul className="pictures">
        {cards.map((card) => (
          <li className="vote">
            <img
              src={`https://cejam.org.br/img/natal/c/${card.arquivo}`}
              alt=""
              onClick={(e) => setSelectedPicture(e.currentTarget.src)}
            />
            <button
              id={card.id}
              className="cta"
              id={card.id}
              onClick={handleVoteSelection}
            >
              Vote
            </button>
          </li>
        ))}
      </ul>

      {confirm ? (
        <div className="confirmation-popup">
          <h5>{message}</h5>
          <div className="row">
            <button type="button" onClick={handleVote} className="cta">
              Confirmar
            </button>
            <button type="button" onClick={handleCancelVote} className="cta">
              Cancelar
            </button>
          </div>
        </div>
      ) : null}

      {timeoutPopup ? (
        <div className="timeout-popup">
          <h5>
            Você pode votar a cada 5 minutos e já votou recentemente, vote
            novamente mais tarde
          </h5>

          <button className="cta" onClick={() => setTimeoutPopup(false)}>
            Fechar
          </button>
        </div>
      ) : null}
      {selectedPicture ? (
        <ImageViewer
          url={selectedPicture}
          setSelectedPicture={setSelectedPicture}
        />
      ) : null}
    </section>
  );
}

export default Votar;

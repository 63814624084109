import React from "react";

import Sobre from "../Sobre";
import Concurso from "../Concurso";
import Votar from "../Votar";
import Bolsas from "../Bolsas";
import Cartoes from "../Cartoes";
import Homenagem from "../Homenagem";
import Programacao from "../Programacao";

import "../../assets/css/secondary.css";

function Pages({ page, setPage }) {
  return (
    <>
      {page === 1 ? <Sobre setPage={setPage} /> : null}
      {page === 2 ? <Concurso setPage={setPage} /> : null}
      {page === 3 ? <Bolsas setPage={setPage} /> : null}
      {page === 4 ? <Cartoes setPage={setPage} /> : null}
      {page === 5 ? <Programacao setPage={setPage} /> : null}
      {page === 6 ? <Homenagem setPage={setPage} /> : null}
    </>
  );
}

export default Pages;

import React from "react";

function Formulario({ url }) {
  return (
    <div className="form">
      <iframe src={url} frameBorder="0"></iframe>
    </div>
  );
}

export default Formulario;

import React, { useEffect, useState } from "react";

import Regulamento from "../Regulamento";
import homenagem from "../../assets/pdf/homenagem.pdf";

import microfone from "../../assets/img/microfone.svg";
import formImg from "../../assets/img/form.svg";
import calendario from "../../assets/img/calendario.svg";
import expand from "../../assets/img/expand.svg";
import Formulario from "../Formulario";

function Homenagem() {
  const [form, setForm] = useState(false);
  const [regulamento, setRegulamento] = useState(false);

  useEffect(() => {
    if (regulamento) setForm(false);
  }, [regulamento]);

  useEffect(() => {
    if (form) setRegulamento(false);
  }, [form]);

  return (
    <section className="content homenagem">
      <div className="heading">
        <h1 className="title">
          Homenagem <br /> <span>“O Amor que nos une”</span>
        </h1>
        <h4>Uma surpresa especial te espera</h4>
      </div>

      <p className="text">
        Marcando o fim de um ciclo e o início de um novo ano, convidamos você
        para participar de uma homenagem especial, resgatando o sentimento de
        confraternização entre o <span>#TimeCEJAM</span> e nos lembrando que,
        apesar dos tempos de distanciamento social, é o amor que nos une.
      </p>

      <div className="wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/dbeOfMkosaM"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          id="homenagem_video"
        ></iframe>
      </div>

      <section className="instructions">
        <div className="heading">
          <h1 className="title">Para participar</h1>
        </div>

        <ul>
          <li>
            <img src={microfone} alt="" />
            <div>
              <p className="message">Solte a voz! </p>
              <p className="text">
                Acompanhando a música abaixo, grave um vídeo cantando ou tocando
                algum instrumento. Seu rosto deve aparecer na gravação, afinal,
                vai que você fica famoso(a) depois dessa homenagem. {":)"}
              </p>
            </div>
          </li>
          <li>
            <img src={formImg} alt="" />
            <div>
              <p className="message">Inscreva-se</p>
              <p className="text">
                Preencha o formulário de inscrição para enviar seu vídeo, ou
                melhor, seu show! O arquivo deve ter até 100 megabytes.
              </p>
            </div>
          </li>
          <li>
            <img src={calendario} alt="" />
            <div>
              <p className="message">
                Anote na agenda: <span>07/12</span>
              </p>
              <p className="text">Uma surpresa especial te espera...</p>
            </div>
          </li>
        </ul>

        <div className="heading">
          <h3>Recado importante:</h3>
          <p className="text">
            Não se esqueça de conferir o regulamento antes de realizar a
            inscrição, ok?{" "}
          </p>
        </div>

        <div className="row">
          <button
            type="button"
            className="cta"
            id="homenagem_regulamento"
            onClick={() => setRegulamento(true)}
          >
            Regulamento
            <img src={expand} alt="" />
          </button>

          <button
            type="button"
            className="cta"
            id="homenagem_participe"
            onClick={() => setForm(true)}
          >
            Faça parte
            <img src={expand} alt="" />
          </button>
        </div>

        {regulamento ? <Regulamento document={homenagem} /> : null}

        {form ? (
          <Formulario url="https://docs.google.com/forms/d/e/1FAIpQLSdTXdx5iXewa6t98I8cezyksCqx5Rut5y80dPX2ySq8NIGkRw/viewform" />
        ) : null}
      </section>
    </section>
  );
}

export default Homenagem;

import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import logoFooter from "./assets/img/logo_cejam.svg";
import ytLogo from "./assets/img/Icons_youtube_copia.svg";
import inLogo from "./assets/img/Icons_LindIn_copia.svg";
import fbLogo from "./assets/img/Icons_face_copia.svg";
import instaLogo from "./assets/img/Icons_Instagram_copia.svg";

import Home from "./components/Home";

import "./assets/css/natal.css";
import Loading from "./components/Loading";
import Pages from "./components/Pages";

function App() {
  const pages = [
    "home",
    "sobre",
    "concurso",
    "bolsas",
    "cartoes",
    "programacao",
    "homenagem",
  ];

  const homeRef = useRef(null);

  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    window.addEventListener("load", (event) => {
      setTimeout(function() {
        setLoading(false);
        setPage(4);
      }, 2000);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      const wrapper = homeRef.current;

      if (page > 0) {
        wrapper.classList = "secondary ";
      } else {
        wrapper.classList = "";
      }

      wrapper.classList += pages[page];

      if (page < 0) setPage(pages.length - 1);
      else if (page > pages.length - 1) setPage(0);

      window.scrollTo(0, 0);
    }
  }, [page]);

  return loading ? (
    <Loading />
  ) : (
    <main id="hotsite-wrapper" ref={homeRef}>
      <div className="overlay-black">

      </div>
      <Home page={page} setPage={setPage} />
      <Pages page={page} setPage={setPage} />
      <footer className="pt-2">
        <div className="row icons-footer">
          <a
            href="https://www.youtube.com/tvcejam"
            target="_blank"
            rel="noopener noreferrer"            
          >
            <img src={ytLogo} alt="Youtube"/>
          </a>
          <a
            href="https://www.facebook.com/cejamoficial/"
            target="_blank"
            rel="noopener noreferrer"            
          >
            <img src={fbLogo} alt="Facebook"/>
          </a>
          <a
            href="https://www.instagram.com/cejamoficial/"
            target="_blank"
            rel="noopener noreferrer"            
          >
            <img src={instaLogo} alt="Instagram"/>
          </a>
          <a
            href="https://pt.linkedin.com/company/cejam"
            target="_blank"
            rel="noopener noreferrer"            
          >
            <img src={inLogo} alt="Linkedin"/>
          </a>
        </div>
        <a href="https://cejam.org.br" className="logo-wrapper">
          <img src={logoFooter} alt="CEJAM" className="logo img-fluid" />
        </a>
      </footer>
    </main>
  );
  // <Suspense fallback={<Loading />}>
}

export default App;

if (document.getElementById("hotsite")) {
  ReactDOM.render(<App />, document.getElementById("hotsite"));
}

import React, { useEffect, useState } from "react";

import Regulamento from "../Regulamento";
import Formulario from "../Formulario";

import expand from "../../assets/img/expand.svg";
import pc from "../../assets/img/pc.svg";
import time from "../../assets/img/time.svg";
import hat from "../../assets/img/hat.svg";

import bolsas from "../../assets/pdf/bolsas.pdf";

function Bolsas() {
  const list = [
    {
      title: "Gestão de Saúde",
      type: "Pós-graduação EAD",
      duration: "18 meses",
      school: "Senac",
    },
    {
      title: "Técnico em Enfermagem",
      type: "Curso técnico presencial (SP)",
      duration: "24 meses",
      school: "Escola CEJAM",
    },
    {
      title: "Técnico em Administração",
      type: "Curso técnico EAD",
      duration: "13 meses",
      school: "Senac",
    },
    {
      title: "Metodologia para Análise e Solução de Problemas",
      type: "Curso livre EAD",
      duration: "30 horas",
      school: "Senac",
    },
    {
      title: "Comunicação Empresarial",
      type: "Curso livre EAD",
      duration: "40 horas",
      school: "Senac",
    },
    {
      title: "Administração de conflitos",
      type: "Curso livre EAD",
      duration: "20 horas",
      school: "Senac",
    },
    {
      title: "Cursos livres",
      type: "A definir",
      duration: "A definir",
      school: "Escola CEJAM",
    },
  ];

  const [current, setCurrent] = useState(0);
  const [selected, setSelected] = useState(list[0]);
  const [regulamento, setRegulamento] = useState(false);
  const [form, setForm] = useState(false);

  useEffect(() => {
    const length = list.length;
    if (current > length - 1) {
      setSelected(list[0]);
      setCurrent(0);
    } else if (current < 0) {
      setSelected(list[length - 1]);
      setCurrent(length - 1);
    } else setSelected(list[current]);
  }, [current]);

  useEffect(() => {
    if (regulamento) setForm(false);
  }, [regulamento]);

  useEffect(() => {
    if (form) setRegulamento(false);
  }, [form]);

  return (
    <section className="content bolsas">
      <div className="heading">
        <h1 className="title">Bolsas de Estudo</h1>
      </div>

      <p className="text">
        Estamos aqui para apoiar o seu sonho. É por isso que queremos incentivar
        o desenvolvimento acadêmico e profissional de nossos colaboradores, por
        meio do sorteio de Bolsas de Estudo integrais!
      </p>

      <section className="info">
        <div className="wrapper courses">
          <button
            type="button"
            className="arrow left"
            aria-label="Left"
            onClick={() => setCurrent((current) => current - 1)}
            id="seta_voltar_bolsas "
          ></button>
          <ul>
            <li>
              <h5>{selected.title}</h5>
              {selected.type ? (
                <p>
                  <img src={pc} alt="" height="32" width="32" /> {selected.type}{" "}
                </p>
              ) : null}
              {selected.duration ? (
                <p>
                  <img src={time} height="32" width="32" /> {selected.duration}
                </p>
              ) : null}
              <p>
                <img src={hat} alt="" height="32" width="32" />{" "}
                {selected.school}
              </p>
            </li>
          </ul>
          <button
            type="button"
            className="arrow right"
            aria-label="Left"
            onClick={() => setCurrent((current) => current + 1)}
            id="seta_avancar_bolsas"
          ></button>
        </div>

        <p className="text">
          Leia o Regulamento e participe da nossa pesquisa interna para
          concorrer às Bolsas de Estudo!*
        </p>
        <p className="message">
          *Somente colaboradores do CEJAM podem participar.
          <br />
          Acesse com uma conta do Gmail e Participe!
        </p>
      </section>
      <section className="row">
        <button
          type="button"
          onClick={() => setRegulamento(!regulamento)}
          className="cta"
          id="bolsas_regulamento"
        >
          Regulamento
          <img src={expand} alt="" />
        </button>

        <button
          type="button"
          onClick={() => setForm(!form)}
          className="cta"
          id="bolsas_participe"
        >
          Concorra
          <img src={expand} alt="" />
        </button>
      </section>

      {regulamento ? <Regulamento document={bolsas} /> : null}
      {form ? (
        <Formulario url="https://docs.google.com/forms/d/e/1FAIpQLSfZ1O8BsNPsaWMxEobCAkfiJbf1etkKTN0rR5NlV7uCf2GJHA/viewform" />
      ) : null}
    </section>
  );
}

export default Bolsas;

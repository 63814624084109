import React, { useState } from "react";

import Regulamento from "../Regulamento";

import concurso from "../../assets/pdf/pequenos-artistas.pdf";

import expand from "../../assets/img/expand.svg";
import regulamento from "../../assets/img/regulamento.svg";
import concluido from "../../assets/img/concluido.svg";
import fotos from "../../assets/img/fotos.svg";
import formulario from "../../assets/img/formulario.svg";
import Formulario from "../Formulario";

function Concurso() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(false);

  return (
    <section className="content concurso">
      <div className="heading">
        <h1 className="title">
          Concurso <br />
          Cultural de Natal
        </h1>
      </div>

      <p className="text">
        Sabemos que incentivar a sensibilidade cultural das nossas crianças é
        essencial, especialmente quando o que importa é se divertir! Esse é o
        propósito do
        <span> Concurso Cultural de Natal</span>, no qual crianças de 7 a 12
        anos que tenham grau de parentesco com colaboradores podem participar
        enviando um desenho autoral com temática natalina.
      </p>

      <p className="text small">
        Os melhores desenhos serão estampados nos cartões de Natal da
        Instituição Disponíveis para download e compartilhamento
      </p>

      <p className="text border">
        Convide um pequeno para participar e incentive-o a soltar a
        criatividade!
      </p>

      <button
        type="button"
        className="cta"
        onClick={() => setOpen(!open)}
        id="concurso_regulamento"
      >
        Regulamento
        <img src={expand} alt="Abrir regulamento" />
      </button>

      {open ? <Regulamento document={concurso} /> : null}

      <section className="regulamento gradient-wrapper">
        <h4>Para participar, basta seguir os passos abaixo:</h4>
        <ol>
          <li>
            <img src={regulamento} alt="" />
            <p>
              <span>1.</span> Leia o Regulamento.
            </p>
          </li>
          <li>
            <img src={fotos} alt="" />
            <p>
              <span>2.</span>Escaneie o desenho em tamanho A4 ou tire uma foto
              com boa qualidade.
            </p>
          </li>
          <li>
            <img src={formulario} alt="" />
            <p>
              <span>3.</span>Preencha o formulário abaixo para efetuar a
              inscrição.
            </p>
          </li>
          <li>
            <img src={concluido} alt="" />
            <p>
              <span>4.</span>Pronto! Você já está participando. Agora é só
              convidar seus colegas para a votação!
            </p>
          </li>
        </ol>
      </section>

      <button
        type="button"
        className="cta"
        onClick={(e) => {
          e.preventDefault();
          setForm(!form);
        }}
        id="concurso_participe"
      >
        Participe
      </button>

      {form ? (
        <Formulario url="https://docs.google.com/forms/d/e/1FAIpQLSfpJeUuW9e7exXDox88RQeLY_oGtxmLworjFt5IZfM71lvY1g/viewform" />
      ) : null}

      <div className="dicas">
        <h2>Dicas Especiais</h2>
        <h4>Para compartilhar com os pequenos</h4>

        <ul>
          <li>
            O que o Natal significa para você? Faça um desenho bem caprichado
            que demonstre como você se sente nessa época tão especial. {":)"}
          </li>

          <li>
            Faça o desenho à mão! Nada de tablets ou computadores nesse momento,
            combinado?
          </li>
          <li>
            Você pode usar lápis de cor, canetinha, giz, guache… Vá até onde a
            imaginação te levar!
          </li>
          <li>Cuidado para não amassar ou rasurar sua obra de arte, ok?</li>
        </ul>
      </div>
    </section>
  );
}

export default Concurso;

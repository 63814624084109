import React from "react";

function Sobre({ setPage }) {
  return (
    <section className="content sobre">
      <div className="heading">
        <h1 className="title">
          Sobre a <br /> campanha
        </h1>
        <h4>Cuidar, amar e acolher</h4>
      </div>

      <p className="text">
        O Natal é uma época que traz consigo o fim de um ciclo e a proximidade
        de um novo ano. É também momento de agradecermos às nossas conquistas e
        de aprendermos com os desafios que enfrentamos. Por isso, o CEJAM
        preparou uma campanha que tem em seu cerne muito cuidado, amor e
        acolhimento, pois sabemos que cada uma das pessoas que integram o nosso
        time é única e especial.
      </p>

      <p className="message">
        Junte-se a nós e torne esse momento ainda mais inesquecível!
      </p>

      <button
        type="button"
        className="cta"
        onClick={() => setPage(5)}
        id="programacao_pagina"
      >
        Programação
      </button>
    </section>
  );
}

export default Sobre;

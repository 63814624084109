import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App.jsx";
// import Snowfall from 'react-snowfall';

ReactDOM.render(
  <React.StrictMode>
    {/* <Snowfall snowflakeCount={30} speed={[0.5, 0.8]} wind={[0.5, 0.5]}/> */}
    <App />
  </React.StrictMode>,
  document.getElementById("hotsite")
);

import React, { useState, useEffect } from "react";

import gincana from "../../assets/pdf/gincana-2020.pdf";
import kit from "../../assets/img/kit.png";
import ImageViewer from "../Votar/ImageViewer";

function Programacao({ setPage }) {
  const [wppUrl, setWppUrl] = useState(null);
  const [selectedPicture, setSelectedPicture] = useState(null);
  useEffect(() => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setWppUrl("whatsapp://send");
    } else {
      setWppUrl("https://wa.me/send/");
    }
  }, []);

  return (
    <section className="content programacao">
      <div className="heading">
        <h1 className="title">Programação</h1>
      </div>

      <ul className="schedule">
        <li>
          <span>
            06 a 30 de <br /> novembro
          </span>
          <div className="content-wrapper">
            <p> Inscrição para o Concurso Cultural “Pequenos Artistas”</p>
            <button onClick={() => setPage(2)}>Ir para inscrição</button>
          </div>
        </li>

        <li>
          <span>
            09 a 30 de <br /> novembro
          </span>
          <div className="content-wrapper">
            <p> Gincana Solidária</p>
            <a href={gincana} download>
              Clique para ver o regulamento
            </a>
          </div>
        </li>

        <li>
          <span>
            16 a 29 de <br /> novembro
          </span>
          <div className="content-wrapper">
            <p>Inscrição para a homenagem “O Amor Que Nos Une”</p>
            <button onClick={() => setPage(6)}>Ir para inscrição</button>
          </div>
        </li>

        <li>
          <span>
            20/11 a 05 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>
              Inscrição para sorteio de Bolsas de Estudo e prêmios exclusivos
            </p>
            <button onClick={() => setPage(3)}>Ir para inscrição</button>
          </div>
        </li>

        <li>
          <span>
            30 de <br /> novembro
          </span>
          <div className="content-wrapper">
            <p>
              1ª parcela do 13º salário <br /> (aos colaboradores que não
              receberam nas férias)
            </p>
          </div>
        </li>

        <li>
          <span>
            01 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>
              Natal Com Saúde dos Idosos. Seja um Voluntário do IRS Dr. Fernando
              Proença de Gouvêa e participe! <br />
              <a href={`${wppUrl}?phone=<5511986470089>&text&app_absent=0`}>
                Clique aqui para saber mais
              </a>{" "}
            </p>
          </div>
        </li>

        <li>
          <span>
            07 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>
              Especial de Natal com #TimeCEJAM <br />
              <a href="https://youtu.be/Ei3eHWifpPM" target="_blank">
                Ir para transmissão
              </a>
            </p>
          </div>
        </li>

        <li>
          <span>
            12 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>Natal Com Saúde das Crianças. Apadrinhe uma Criança! </p>
            <a href={`${wppUrl}?phone=<5511986470089>&text&app_absent=0`}>
              Clique aqui para saber mais
            </a>
          </div>
        </li>

        <li>
          <span>
            14 a 18 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>Entrega de Ceias de Natal (Kits Gelados)</p>
            <button onClick={() => setSelectedPicture(kit)}>
              Clique para ver
            </button>
          </div>
        </li>

        <li>
          <span>
            20 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>Corrida & Caminhada CEJAM</p>

            <a
              href="https://cejam.org.br/eventos/6-corrida--caminhada-cejam"
              target="_blank"
            >
              Clique aqui para saber mais
            </a>
          </div>
        </li>

        <li>
          <span>
            20 de <br /> dezembro
          </span>
          <div className="content-wrapper">
            <p>2ª parcela do 13º salário</p>
          </div>
        </li>
      </ul>
      {selectedPicture ? (
        <ImageViewer url={kit} setSelectedPicture={setSelectedPicture} />
      ) : null}
    </section>
  );
}

export default Programacao;

import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

function Regulamento({ document }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (pageNumber < 1) setPageNumber(1);
    else if (pageNumber > numPages) setPageNumber(numPages);
  }, [pageNumber]);

  function onLoadSuccessDocument({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <section className="regulamento-pdf show">
      <Document file={document} onLoadSuccess={onLoadSuccessDocument}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        <button
          type="button"
          onClick={() => setPageNumber((pageNumber) => pageNumber - 1)}
        >
          {"<"}{" "}
        </button>
        Página {pageNumber} de {numPages}
        <button
          type="button"
          onClick={() => setPageNumber((pageNumber) => pageNumber + 1)}
        >
          {">"}
        </button>
      </p>
    </section>
  );
}

export default Regulamento;

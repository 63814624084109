import React, { useEffect, useState } from "react";

import logo from "../../assets/img/logo.svg";

function Home({ setPage }) {
  const imgArray = [
    "https://static1.patasdacasa.com.br/articles/5/19/5/@/473-veja-30-curiosidades-sobre-gatos-articles_media_mobile-1.jpg",
    "https://meupet.bayer.com/sites/g/files/adhwdz661/files/styles/image_642w_x_424h/public/2020-07/retrato_de_um_gato_malhado_domestico_de_pelos_curtos_na_frente_de_flores_azuis.jpg?itok=oFCBdDFR",
    "https://pfarma.com.br/images/noticias/gato-coronavirus.jpg",
    "https://s2.glbimg.com/5IEojOCGN6bgFV5L2K_RKB5dtvk=/e.glbimg.com/og/ed/f/original/2020/03/31/cat-4548812_960_720.jpg",
  ];

  const [current, setCurrent] = useState(0);

  const handleClick = () => {
    if (current < imgArray.length) {
      setCurrent((current) => current + 1);
    } else {
      setCurrent(0);
    }
  };

  return (
    <>
      {/* <nav>
        <ul role="list">
          <li className="sobre" role="listitem">
            <button onClick={() => setPage(1)} id="sobre_menu">
              Sobre
            </button>
          </li>
          <li className="concurso" role="listitem">
            <button onClick={() => setPage(2)} id="concurso_menu">
              Concurso
            </button>
          </li>
          <li className="bolsas" role="listitem">
            <button onClick={() => setPage(3)} id="bolsas_menu">
              Bolsas de estudo
            </button>
          </li>
          <div className="wrapper" role="listitem">
            <button
              type="button"
              className="arrow left"
              aria-label="Left"
              onClick={() => setPage((page) => page - 1)}
              id="seta_voltar"
            ></button>

            <img
              src={logo}
              alt="Natal com #TimeCEJAM"
              onClick={() => setPage(0)}
            />
            <button
              onClick={() => setPage((page) => page + 1)}
              type="button"
              aria-label="Right"
              className="arrow right"
              id="seta_avancar"
            ></button>
          </div>
          <li className="cartoes" role="listitem">
            <button onClick={() => setPage(4)} id="cartoes_menu">
              Cartões de Natal
            </button>
          </li>
          <li className="programacao" role="listitem">
            <button onClick={() => setPage(5)} id="programacao_menu">
              Programação
            </button>
          </li>
          <li className="homenagem" role="listitem">
            <button onClick={() => setPage(6)} id="homenagem_menu">
              Homenagem
            </button>
          </li>
        </ul>
      </nav> */}

      {/* <div className="shadow">
        <section className="rounded">
          <div className="wrapper">
            <button
              type="button"
              className="arrow left"
              aria-label="Left"
              onClick={handleClick}
            ></button>

            <img src={imgArray[current]} className="carousel" alt="teste" />

            <button
              onClick={handleClick}
              type="button"
              className="arrow right"
              aria-label="Right"
            ></button>
          </div>
        </section>
      </div> */}
    </>
  );
}

export default Home;

import React from "react";

function ImageViewer({ url, setSelectedPicture }) {
  return (
    <div className="image-viewer">
      <img src={url} alt="" />
      <button
        type="button"
        onClick={() => setSelectedPicture(null)}
        className="cta"
      >
        Fechar
      </button>
    </div>
  );
}

export default ImageViewer;
